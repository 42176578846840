import { useState } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { fetchPastOrders, fetchPendingOrders, fetchStorefrontsOrders } from '~/src/services/OrderService'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PAST_ORDERS_STATE, PENDING_ORDERS_STATE } from '~/src/stores/orders'
import { Id, OrderSummary } from '@eo-storefronts/eo-core'
import { BRAND_STATE } from '~/src/stores/brand'

interface ReturnsType {
  fetch(customerId?: Id): Promise<unknown>,
  loading: boolean,
}

const useFetchAllOrders = (): ReturnsType => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const firm = useEoValue(FIRM_SELECTOR)
  const brand = useEoValue(BRAND_STATE)
  const setPastOrders = useSetEoState(PAST_ORDERS_STATE)
  const setPendingOrders = useSetEoState(PENDING_ORDERS_STATE)

  const fetch = async (customerId?: Id): Promise<unknown> => {
    if (!customerId || !firm && !brand) {
      setLoading(false)

      return Promise.resolve()
    }

    setLoading(true)

    if (firm) {
      return Promise.all([
        fetchPastOrders(firm.id, firm.uuid ?? '', customerId),
        fetchPendingOrders(firm.id, firm.uuid ?? '', customerId)
      ])
        .then(([ pastOrders, pendingOrders ]) => {
          setPastOrders(pastOrders)
          setPendingOrders(pendingOrders)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (brand) {
      return fetchStorefrontsOrders(brand.id)
        .then((response: OrderSummary[]) => {
          setPastOrders(response.filter((order: OrderSummary) => order.status === 'HANDLED'))
          setPendingOrders(response.filter((order: OrderSummary) => order.status !== 'HANDLED'))
        })
        .catch(() => {
          // Nothing
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return Promise.resolve()
  }

  return { fetch, loading }
}

export {
  useFetchAllOrders
}
